<template>
  <div class="e-content">
    <div id="Axios">
      <h1>API</h1>
      <p>API를 활용하기 위해 Axios 라이브러리를 사용합니다.
      </p>
      <b-card title="Axios란?">
        <b-card-text>
          Axios는 브라우저와 node.js에서 사용할 수 있는 Promise 기반 HTTP 클라이언트 라이브러리입니다.
        </b-card-text>
        <b-card-text>
          서버 사이드에서는 네이티브 node.js의 http 모듈을 사용하고, <br/>클라이언트(브라우저)에서는 XMLHttpRequests를 사용합니다.
        </b-card-text>
      </b-card>

      <b-card id="HowtoUse" title="사용 방법">
        <b-card-text>📌 더 자세한 사용법:
          <b-link
              href="https://axios-http.com/kr/"
              target="_blank"
          >
            https://axios-http.com/kr/
          </b-link>
        </b-card-text>
        <br/>
        <b-card-text>아래 명령어를 사용해 axios를 설치합니다.
        </b-card-text>
        <div class="card-code">
          <prism language="markup">
            {{ codeNpm }}
          </prism>
        </div>
        <b-card-text>프로젝트에서 사용되는 라이브러리를 담아 놓기 위해 libs 폴더를 생성합니다.</b-card-text>
        <b-card-text>libs 폴더 내 axios.js 파일을 생성합니다.</b-card-text>
        <b-card-text>axios.js 파일 내 아래와 같이 axios를 import 합니다.</b-card-text>
        <h5>📁 libs/axios.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeImportAxios }}</code></pre>
        </div>
        <b-card-text>새로운 Axios 인스턴스를 만들고, HTTP header에 API 요청 시 실어 보내는 데이터 타입 정보를 입력합니다.</b-card-text>
        <b-card-text>'application/json'은 {key:value} 형태로 전송됩니다.</b-card-text>
        <h5>📁 libs/axios.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{ codeAxios }}</code></pre>
        </div>
        <b-card-text>main.js에 axios.js를 import합니다.</b-card-text>
        <h5>📁 main.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeAxiosMainJs }}</code></pre>
        </div>
      </b-card>

      <b-card>
        <b-card-text>1. 새로 만든 Axios 인스턴스를 prototype으로 정의해 각 Vue instance에서 사용</b-card-text>
        <b-card-text> 📚 참고:
          <b-link
              href="https://kr.vuejs.org/v2/cookbook/adding-instance-properties.html"
              target="_blank"
          >
            Vue instance property 추가하기
          </b-link>
        </b-card-text>
        <h5>📁 libs/axios.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{ codePrototype }}</code></pre>
        </div>
        <b-card-text>이제 $http는 this.$http를 통해 모든 Vue instance에서 사용할 수 있습니다.</b-card-text>
        <b-card-text>아래 코드는 axios로 서버와 통신하는 방법입니다.</b-card-text>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeUsingAxios }}</code></pre>
        </div>
        <b-card-text>아래 코드는 Vue instance를 사용해 axios로 서버와 통신하는 방법입니다.</b-card-text>
        <h5>📁 views/Home.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codePrototypeHttp }}</code></pre>
        </div>
        <b-button
            class="btn btn-warning"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-1"
            @click="visible = !visible"
        >
          전체 코드
        </b-button>
        <b-collapse id="collapse-1" v-model="visible" class="mt-2">
          <div class="card-code">
              <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
                          {{ codeUsingAxiosViaInstance }}

                </code>
              </pre>
          </div>
        </b-collapse>
      </b-card>

      <b-card>
        <b-card-text>2. Mixins 사용 (Mixins는 Vue 컴포넌트에 재사용 가능한 기능을 배포하는 방법입니다.)</b-card-text>
        <b-card-text> 📚 참고:
          <b-link
              href="https://kr.vuejs.org/v2/guide/mixins.html"
              target="_blank"
          >
            Vue Mixins
          </b-link>
        </b-card-text>
        <b-card-text>mixins 폴더 내 index.js 파일 생성 후 아래 코드를 입력합니다.</b-card-text>
        <h5>📁 mixins/index.js</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{ codeMixins }}</code></pre>
        </div>
        <b-card-text>axios를 사용할 곳에 mixins을 import 시킨 후 사용합니다.</b-card-text>
        <h5>📁 views/Home.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeImportMixins }}</code></pre>
        </div>
        <b-button
            class="btn btn-warning"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-1"
            @click="visible = !visible"
        >
          전체 코드
        </b-button>
        <b-collapse id="collapse-1" v-model="visible" class="mt-2">
          <div class="card-code">
              <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
                          {{ codeUsingAxiosViaMixins }}
                </code>
              </pre>
          </div>
        </b-collapse>
      </b-card>

      <b-card>
        <b-card-text>3. axios 직접 사용</b-card-text>
        <b-card-text>axios를 사용할 컴포넌트에 import해서 사용합니다.</b-card-text>
        <h5>📁 views/Home.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeUsingAxiosDirect }}</code></pre>
        </div>
      </b-card>

      <b-card id="예제" title="7초마다 데이터 가져오기">
        <b-card-text>Beat Someone Scan은 웹소켓을 사용하지 않고 <code>setInterval</code>함수를 이용해 데이터를 가져옵니다.</b-card-text>
        <b-card-text>this.latestBlocks() 함수를 7초 마다 실행합니다.</b-card-text>
        <h5>📁 views/Home.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeUsingAxiosSetInterval }}</code></pre>
        </div>
        <b-card-text>💣 주의할 점: Vue.js는 SPA로 동작해서 페이지가 넘어간 것처럼 보이지만 결국은 단일 페이지로 구성되어 있습니다. 그래서 다음 페이지로 넘어가도
          <code>setInterval</code>함수는 계속해서 실행됩니다. 페이지 이동 후 <code>setInterval</code>함수가 실행되지 않게 하기위해서는
          <code>clearInterval</code>함수를 사용해 <code>setInterval</code>함수를 종료시켜야 합니다.</b-card-text>
        <b-card-text> <p><span class="font-weight-bold">beforeDestroy</span> 컴포넌트가 제거 되기 직전에 호출되는 라이프 사이클 훅으로 이 훅에서 컴포넌트는 본래의 기능들을 가지고 있는 온전한 상태입니다.
          beforeDestroy에서는 이벤트 리스터를 해제하거나 컴포넌트에서 동작으로 할당 받는 자원들을 해제해야할 때 적합한하여 이 훅에서 <code>clearInterval</code>함수를 실행시킵니다.
        </p></b-card-text>
        <h5>📁 views/Home.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeUsingAxiosClearInterval }}</code></pre>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BImg, BButton,
  BCollapse, BLink
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  codeImportAxios,
  codeAxios,
  codeAxiosMainJs,
  codePrototype,
  codePrototypeHttp,
  codeUsingAxios,
  codeUsingAxiosViaInstance,
  codeMixins,
  codeImportMixins,
  codeUsingAxiosViaMixins,
  codeUsingAxiosDirect,
  codeUsingAxiosSetInterval,
  codeUsingAxiosClearInterval
} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
    BCollapse,
    BLink,
    BCardCode,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['Axios','HowtoUse'],
      visible: false,
      codeImportAxios,
      codeAxios,
      codeAxiosMainJs,
      codePrototype,
      codePrototypeHttp,
      codeUsingAxios,
      codeUsingAxiosViaInstance,
      codeMixins,
      codeImportMixins,
      codeUsingAxiosViaMixins,
      codeUsingAxiosDirect,
      codeUsingAxiosSetInterval,
      codeUsingAxiosClearInterval,
      codeNpm:
          `
# For Yarn
yarn add axios

# For npm
npm install axios
`
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

